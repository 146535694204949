$(".custom-carousel").owlCarousel({
  autoWidth: true,
  loop: true,
  center: true,
  startPosition: 2,
  margin: 24,
  rewind: false,
  dots: false,
  lazyLoad: true
})
$(".few-carousel").owlCarousel({
  autoWidth: true,
  loop: false,
  center: false,
  startPosition: 2,
  margin: 24,
  rewind: false,
  dots: false,
  lazyLoad: true
})
$(".single-carousel").owlCarousel({
  autoWidth: true,
  loop: false,
  center: true,
  startPosition: 2,
  margin: 24,
  rewind: false,
  dots: false,
  lazyLoad: true
})